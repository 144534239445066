<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            required: required$ | async,
            optionList: options$ | async,
            disabled: disabled.$ | async,
            values: values.$ | async
        } as data"
    >
        <ng-container *ngIf="ngControl.control; let control">
            <div
                class="bazis-control"
                [ngClass]="{
                    'bazis-control--invalid': ngControl.control.invalid,
                    'bazis-control--disabled': data.disabled
                }"
                clickOutside
                (clickOutside)="hideList()"
            >
                <bazis-label
                    *ngIf="!isInnerlabel"
                    [id]="attrId"
                    [isMajor]="hasTitleMajor"
                    [isEmptyLabel]="isEmptyLabel"
                    [title]="title || t(titleKey, titleParams)"
                    [required]="data.required"
                >
                    <bazis-interrogation
                        *ngIf="tooltipKey"
                        [slot]="tooltipSettings?.targetSide"
                        iconSize="xs"
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipOffset]="[-8, 4]"
                        [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                        [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
                    ></bazis-interrogation>
                </bazis-label>

                <div
                    class="bazis-control__field-group"
                    [class.bazis-control__field-group--activated]="showList"
                    [class.bazis-control__field-group--disabled]="data.disabled"
                >
                    <bazis-control-field-start
                        [isClickable]="isLeftIconClickable"
                        [icon]="leftIcon"
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipSettings]="tooltipSettings"
                        (actionClick)="onLeftIconClick($event)"
                    >
                    </bazis-control-field-start>

                    <!-- START inner label -->
                    <bazis-label
                        *ngIf="isInnerlabel"
                        [id]="attrId"
                        [isMajor]="hasTitleMajor"
                        [isEmptyLabel]="isEmptyLabel"
                        [title]="(title || t(titleKey, titleParams)) + ':'"
                        [required]="data.required"
                        [isInner]="true"
                    >
                        <bazis-interrogation
                            *ngIf="tooltipKey"
                            [slot]="tooltipSettings?.targetSide"
                            iconSize="xs"
                            [tooltipText]="t(tooltipKey, tooltipParams)"
                            [tooltipOffset]="[-8, 4]"
                            [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                            [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
                        ></bazis-interrogation>
                    </bazis-label>
                    <!-- END inner label -->

                    <div class="bazis-control__field">
                        <bazis-select-native
                            [attrId]="attrId"
                            [options]="data.optionList"
                        ></bazis-select-native>

                        <!-- Прекрасный кастомный селект -->
                        <div
                            class="bazis-select"
                            [class.bazis-select_secondary]="type === 'secondary'"
                            aria-hidden="true"
                        >
                            <div
                                *ngIf="selectedValues$ | async as selectedValues"
                                class="bazis-select__trigger"
                                [class.bazis-select__trigger_noempty]="selectedValues.length"
                                (click)="toggleList()"
                            >
                                <span *ngIf="selectedValues.length; else tplNotSelectedOptions">
                                    <ng-container
                                        *ngFor="let selected of selectedValues; let i = index"
                                    >
                                        <bazis-item-with-icon-value
                                            [item]="selected"
                                        ></bazis-item-with-icon-value>
                                        {{ i < selectedValues.length - 1 ? ',' : '' }}
                                    </ng-container>
                                </span>
                                <ng-template #tplNotSelectedOptions>
                                    <span *ngIf="optionLabel">{{ t(optionLabel) }}</span>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <bazis-control-select-end
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipSettings]="tooltipSettings"
                        [iconUp]="iconUp"
                        [iconDown]="iconDown"
                        [showList]="showList"
                        (toggled)="toggleList()"
                    >
                    </bazis-control-select-end>

                    <div
                        class="bazis-list bazis-list_control-dd"
                        [hidden]="!showList"
                    >
                        <bazis-multiple-selection-option
                            *ngFor="let option of data.optionList"
                            [option]="option"
                            [byCheckbox]="byCheckbox"
                            [multiple]="multiple"
                            [values]="data.values"
                            (toggled)="toggleValue(option.id)"
                        ></bazis-multiple-selection-option>
                    </div>
                </div>

                <div class="bazis-control__error">
                    <bazis-form-error [formControl]="ngControl.control"></bazis-form-error>
                </div>

                <div
                    *ngIf="noteKey"
                    class="bazis-control__note"
                >
                    {{ noteKey }}
                </div>

                <ng-content select="comment"></ng-content>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
